import { colorsNewReverse, colorsReverse } from "./lyneUphelpers"
import { colorsReverseLyneFit } from "./lyneFitHelper"
import { colorsReverseLyneHome } from "./lyneHomeHelper"
import moment from "moment-business-days"

export const productTitle = (title, variant, locale) => {
  let newTitle = ""
  let variantPrice = variant.price.amount ? variant.price.amount : variant.price
  variant = variant.selectedOptions
  if (locale === "en") {
    if (title === "LyneUp") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne up – everyday vest women –"
      } else {
        newTitle = newTitle + "Lyne up – everyday vest men –"
      }
    } else if (title === "LyneHome") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne home women – vest for at home –"
      } else {
        newTitle = newTitle + "Lyne home men – vest for at home –"
      }
    } else if (title === "LyneFit") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne fit – sports t-shirt women –"
      } else {
        newTitle = newTitle + "Lyne fit – sports t-shirt men –"
      }
    } else if (title === "Loop") {
      newTitle = newTitle + "Loop - self-massage accessory"
    } else if (title.includes("gift-card")) {
      if (title.includes("lyneup")) newTitle = newTitle + "E-gift card LyneUp"
      else if (title.includes("lynefit"))
        newTitle = newTitle + "E-gift card LyneFit"
      else if (title.includes("lynepro"))
        newTitle = newTitle + "E-gift card LynePro"
      else if (title.includes("loop-card"))
        newTitle = newTitle + "E-gift card Combo"
      else if (title.includes("Loop"))
        newTitle = newTitle + "Loop - self-massage accessory"
      else newTitle = newTitle + "E-gift card"
    } else if (
      title.includes("E-carte cadeau") ||
      title.includes("E-carte cadeau offerte")
    ) {
      newTitle = newTitle + "E-carte cadeau PERCKO de"
    } else if (title === "Matelas") {
      newTitle = newTitle + "Matelas hybride premium qui soulage le dos –"
    } else if (title === "1 Matelas + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas hybride premium"
    } else if (title === "Matelas Mousse") {
      newTitle =
        newTitle + "Matelas mousse mémoire de forme qui soulage le dos –"
    } else if (title === "1 Matelas Mousse + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas mousse mémoire de forme"
    } else if (title === "Oreiller") {
      newTitle =
        newTitle + "Oreiller universel, l'allié ergonomique pour le dos "
    } else if (title === "Oreiller dorsal+") {
      newTitle = newTitle + "Oreiller dorsal+, l'allié ergonomique pour le dos"
    } else if (title === "Oreiller plat") {
      newTitle = newTitle + "Oreiller plat, l'allié ergonomique pour le dos"
    } else if (title === "Sommier Original") {
      newTitle = newTitle + "Sommier zoné - original –"
    } else if (title === "Sommier Coffre") {
      newTitle = newTitle + "Sommier zoné - coffre –"
    } else if (title === "Sommier Bois Massif") {
      newTitle = newTitle + "Sommier zoné - bois massif –"
    } else if (title === "Sommier zoné - électrique") {
      newTitle = newTitle + "Sommier zoné - électrique –"
    } else if (title === "Chaise ergonomique") {
      newTitle = newTitle + "Chaise de bureau ergonomique"
    } else if (title === "Surmatelas") {
      newTitle = newTitle + "Surmatelas –"
    } else if (title === "T-SHIRT DISCRET - HOMME") {
      newTitle = newTitle + "T-shirt discret - Homme –"
    } else if (title === "T-SHIRT DISCRET - FEMME") {
      newTitle = newTitle + "T-shirt discret - Femme –"
    } else if (title === "T-SHIRT DE SPORT - HOMME") {
      newTitle = newTitle + "T-shirt de sport - Homme –"
    } else if (title === "T-SHIRT DE SPORT - FEMME") {
      newTitle = newTitle + "T-shirt de sport - Femme –"
    } else if (title === "GILET PRATIQUE - HOMME") {
      newTitle = newTitle + "Gilet pratique - Homme –"
    } else if (title === "GILET PRATIQUE - FEMME") {
      newTitle = newTitle + "Gilet pratique - Femme –"
    }
  } else if (locale === "de") {
    if (title === "LyneUp") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne up – unterhemd damen –"
      } else {
        newTitle = newTitle + "Lyne up – unterhemd herren –"
      }
    } else if (title === "LyneHome") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne home männer – weste für zuhause –"
      } else {
        newTitle = newTitle + "Lyne home frauen – weste für zuhause –"
      }
    } else if (title === "LyneFit") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne fit – sportshirt damen –"
      } else {
        newTitle = newTitle + "Lyne fit – sportshirt herren –"
      }
    } else if (title === "Loop") {
      newTitle = newTitle + "Loop - self-massage accessory"
    } else if (title.includes("gift-card")) {
      if (title.includes("lyneup")) newTitle = newTitle + "E-gift card LyneUp"
      else if (title.includes("lynefit"))
        newTitle = newTitle + "E-gift card LyneFit"
      else if (title.includes("lynepro"))
        newTitle = newTitle + "E-gift card LynePro"
      else if (title.includes("loop-card"))
        newTitle = newTitle + "E-gift card Combo"
      else if (title.includes("Loop"))
        newTitle = newTitle + "Loop - self-massage accessory"
      else newTitle = newTitle + "E-gift card"
    } else if (
      title.includes("E-carte cadeau") ||
      title.includes("E-carte cadeau offerte")
    ) {
      newTitle = newTitle + "E-carte cadeau PERCKO de"
    } else if (title === "Matelas") {
      newTitle = newTitle + "Matelas hybride premium qui soulage le dos –"
    } else if (title === "1 Matelas + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas hybride premium"
    } else if (title === "Matelas Mousse") {
      newTitle =
        newTitle + "Matelas mousse mémoire de forme qui soulage le dos –"
    } else if (title === "1 Matelas Mousse + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas mousse mémoire de forme"
    } else if (title === "Oreiller") {
      newTitle =
        newTitle + "Oreiller universel, l'allié ergonomique pour le dos "
    } else if (title === "Oreiller dorsal+") {
      newTitle = newTitle + "Oreiller dorsal+, l'allié ergonomique pour le dos"
    } else if (title === "Oreiller plat") {
      newTitle = newTitle + "Oreiller plat, l'allié ergonomique pour le dos"
    } else if (title === "Sommier Original") {
      newTitle = newTitle + "Sommier zoné - original –"
    } else if (title === "Sommier Coffre") {
      newTitle = newTitle + "Sommier zoné - coffre –"
    } else if (title === "Sommier Bois Massif") {
      newTitle = newTitle + "Sommier zoné - bois massif –"
    } else if (title === "Sommier zoné - électrique") {
      newTitle = newTitle + "Sommier zoné - électrique –"
    } else if (title === "Chaise ergonomique") {
      newTitle = newTitle + "Chaise de bureau ergonomique"
    } else if (title === "Surmatelas") {
      newTitle = newTitle + "Surmatelas –"
    } else if (title === "T-SHIRT DISCRET - HOMME") {
      newTitle = newTitle + "T-shirt discret - Homme –"
    } else if (title === "T-SHIRT DISCRET - FEMME") {
      newTitle = newTitle + "T-shirt discret - Femme –"
    } else if (title === "T-SHIRT DE SPORT - HOMME") {
      newTitle = newTitle + "T-shirt de sport - Homme –"
    } else if (title === "T-SHIRT DE SPORT - FEMME") {
      newTitle = newTitle + "T-shirt de sport - Femme –"
    } else if (title === "GILET PRATIQUE - HOMME") {
      newTitle = newTitle + "Gilet pratique - Homme –"
    } else if (title === "GILET PRATIQUE - FEMME") {
      newTitle = newTitle + "Gilet pratique - Femme –"
    }
  } else if (locale === "es") {
    if (title === "LyneUp") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne up – camiseta interior mujer –"
      } else {
        newTitle = newTitle + "Lyne up – camiseta interior hombre –"
      }
    } else if (title === "LyneHome") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne home mujer – chaleco para la casa –"
      } else {
        newTitle = newTitle + "Lyne home hombre – chaleco para la casa –"
      }
    } else if (title === "LyneFit") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne fit – camiseta deportiva mujer –"
      } else {
        newTitle = newTitle + "Lyne fit – camiseta deportiva hombre –"
      }
    } else if (title === "Loop") {
      newTitle = newTitle + "Loop - self-massage accessory"
    } else if (title.includes("gift-card")) {
      if (title.includes("lyneup")) newTitle = newTitle + "E-gift card LyneUp"
      else if (title.includes("lynefit"))
        newTitle = newTitle + "E-gift card LyneFit"
      else if (title.includes("lynepro"))
        newTitle = newTitle + "E-gift card LynePro"
      else if (title.includes("loop-card"))
        newTitle = newTitle + "E-gift card Combo"
      else if (title.includes("Loop"))
        newTitle = newTitle + "Loop - self-massage accessory"
      else newTitle = newTitle + "E-gift card"
    } else if (
      title.includes("E-carte cadeau") ||
      title.includes("E-carte cadeau offerte")
    ) {
      newTitle = newTitle + "E-carte cadeau PERCKO de"
    } else if (title === "Matelas") {
      newTitle = newTitle + "Matelas hybride premium qui soulage le dos –"
    } else if (title === "1 Matelas + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas hybride premium"
    } else if (title === "Matelas Mousse") {
      newTitle =
        newTitle + "Matelas mousse mémoire de forme qui soulage le dos –"
    } else if (title === "1 Matelas Mousse + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas mousse mémoire de forme"
    } else if (title === "Oreiller") {
      newTitle =
        newTitle + "Oreiller universel, l'allié ergonomique pour le dos "
    } else if (title === "Oreiller dorsal+") {
      newTitle = newTitle + "Oreiller dorsal+, l'allié ergonomique pour le dos"
    } else if (title === "Oreiller plat") {
      newTitle = newTitle + "Oreiller plat, l'allié ergonomique pour le dos"
    } else if (title === "Sommier Original") {
      newTitle = newTitle + "Sommier zoné - original –"
    } else if (title === "Sommier Coffre") {
      newTitle = newTitle + "Sommier zoné - coffre –"
    } else if (title === "Sommier Bois Massif") {
      newTitle = newTitle + "Sommier zoné - bois massif –"
    } else if (title === "Sommier zoné - électrique") {
      newTitle = newTitle + "Sommier zoné - électrique –"
    } else if (title === "Chaise ergonomique") {
      newTitle = newTitle + "Chaise de bureau ergonomique"
    } else if (title === "Surmatelas") {
      newTitle = newTitle + "Surmatelas –"
    } else if (title === "T-SHIRT DISCRET - HOMME") {
      newTitle = newTitle + "T-shirt discret - Homme –"
    } else if (title === "T-SHIRT DISCRET - FEMME") {
      newTitle = newTitle + "T-shirt discret - Femme –"
    } else if (title === "T-SHIRT DE SPORT - HOMME") {
      newTitle = newTitle + "T-shirt de sport - Homme –"
    } else if (title === "T-SHIRT DE SPORT - FEMME") {
      newTitle = newTitle + "T-shirt de sport - Femme –"
    } else if (title === "GILET PRATIQUE - HOMME") {
      newTitle = newTitle + "Gilet pratique - Homme –"
    } else if (title === "GILET PRATIQUE - FEMME") {
      newTitle = newTitle + "Gilet pratique - Femme –"
    }
  } else {
    if (title === "LyneUp") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle =
          newTitle + "Lyne up femme – sous-vêtement pour le quotidien –"
      } else {
        newTitle =
          newTitle + "Lyne up homme – sous-vêtement pour le quotidien –"
      }
    } else if (title === "LyneHome") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne home femme – gilet pour la maison –"
      } else {
        newTitle = newTitle + "Lyne home homme – gilet pour la maison –"
      }
    } else if (title === "LyneFit") {
      if (variant.find(item => item.name === "Gender").value === "Female") {
        newTitle = newTitle + "Lyne fit femme – t-shirt pour le sport –"
      } else {
        newTitle = newTitle + "Lyne fit homme – t-shirt pour le sport –"
      }
    } else if (title === "Patches") {
      newTitle = newTitle + "Rrcharge patchs adhésifs"
    } else if (title === "Loop") {
      newTitle = newTitle + "Loop - accessoire d'automassage"
    } else if (title.includes("gift-card")) {
      if (title.includes("lyneup")) newTitle = newTitle + "E-gift card LyneUp"
      else if (title.includes("lynefit"))
        newTitle = newTitle + "E-gift card LyneFit"
      else if (title.includes("lynepro"))
        newTitle = newTitle + "E-gift card LynePro"
      else if (title.includes("loop-card"))
        newTitle = newTitle + "E-gift card Combo"
      else if (title.includes("Loop"))
        newTitle = newTitle + "Loop - accessoire d'automassage"
      else newTitle = newTitle + "E-carte cadeau"
    } else if (
      title.includes("E-carte cadeau") ||
      title.includes("E-carte cadeau offerte")
    ) {
      newTitle = newTitle + "E-carte cadeau PERCKO de"
    } else if (title === "Matelas") {
      newTitle = newTitle + "Matelas hybride premium qui soulage le dos –"
    } else if (title === "1 Matelas + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas hybride premium"
    } else if (title === "Matelas Mousse") {
      newTitle =
        newTitle + "Matelas mousse mémoire de forme qui soulage le dos –"
    } else if (title === "1 Matelas Mousse + Oreiller") {
      newTitle = newTitle + "Pack : 1 matelas mousse mémoire de forme"
    } else if (title === "Oreiller") {
      newTitle =
        newTitle + "Oreiller universel, l'allié ergonomique pour le dos "
    } else if (title === "Oreiller dorsal+") {
      newTitle = newTitle + "Oreiller dorsal+, l'allié ergonomique pour le dos"
    } else if (title === "Oreiller plat") {
      newTitle = newTitle + "Oreiller plat, l'allié ergonomique pour le dos"
    } else if (title === "Sommier Original") {
      newTitle = newTitle + "Sommier zoné - original –"
    } else if (title === "Sommier Coffre") {
      newTitle = newTitle + "Sommier zoné - coffre –"
    } else if (title === "Sommier Bois Massif") {
      newTitle = newTitle + "Sommier zoné - bois massif –"
    } else if (title === "Sommier zoné - électrique") {
      newTitle = newTitle + "Sommier zoné - électrique –"
    } else if (title === "Chaise ergonomique") {
      newTitle = newTitle + "Chaise de bureau ergonomique"
    } else if (title === "Surmatelas") {
      newTitle = newTitle + "Surmatelas –"
    } else if (title === "T-SHIRT DISCRET - HOMME") {
      newTitle = newTitle + "T-shirt discret - Homme –"
    } else if (title === "T-SHIRT DISCRET - FEMME") {
      newTitle = newTitle + "T-shirt discret - Femme –"
    } else if (title === "T-SHIRT DE SPORT - HOMME") {
      newTitle = newTitle + "T-shirt de sport - Homme –"
    } else if (title === "T-SHIRT DE SPORT - FEMME") {
      newTitle = newTitle + "T-shirt de sport - Femme –"
    } else if (title === "GILET PRATIQUE - HOMME") {
      newTitle = newTitle + "Gilet pratique - Homme –"
    } else if (title === "GILET PRATIQUE - FEMME") {
      newTitle = newTitle + "Gilet pratique - Femme –"
    }
  }

  if (title === "LyneUp") {
    newTitle =
      newTitle +
      " " +
      colorsReverse[locale][variant.find(item => item.name === "Color").value] +
      " , " +
      variant.find(item => item.name === "Size").value
  } else if (title === "LyneHome") {
    newTitle =
      newTitle +
      " " +
      colorsReverseLyneHome[locale]["BLUE"] +
      " , " +
      variant.find(item => item.name === "Size").value
  } else if (title === "LyneFit") {
    newTitle =
      newTitle +
      " " +
      colorsReverseLyneFit[locale][
        variant.find(item => item.name === "Color").value
      ] +
      " , " +
      variant.find(item => item.name === "Size").value
  } else if (
    title === "1 Matelas + Oreiller" ||
    title === "1 Matelas Mousse + Oreiller"
  ) {
    newTitle =
      newTitle +
      " " +
      variant.find(item => item.name === "Size").value +
      " " +
      (Number(variantPrice).toFixed(0) > 850
        ? "+ 2 oreillers universels"
        : "+ 1 oreiller universel")
  } else if (
    title === "Matelas" ||
    title === "Matelas Mousse" ||
    title === "Surmatelas" ||
    title === "Sommier Original" ||
    title === "Sommier Coffre" ||
    title === "Sommier Bois Massif" ||
    title === "Sommier zoné - électrique"
  ) {
    newTitle = newTitle + " " + variant.find(item => item.name === "Size").value
  } else if (
    title === "T-SHIRT DISCRET - HOMME" ||
    title === "GILET PRATIQUE - HOMME" ||
    title === "GILET PRATIQUE - FEMME"
  ) {
    newTitle =
      newTitle +
      " " +
      colorsNewReverse[locale][
        variant.find(item => item.name === "Color").value
      ] +
      " - " +
      variant.find(item => item.name === "Size").value
  } else if (
    title === "T-SHIRT DE SPORT - HOMME" ||
    title === "T-SHIRT DE SPORT - FEMME"
  ) {
    newTitle =
      newTitle +
      " " +
      (variant.find(item => item.name === "Color").value === "Noir"
        ? "Noir respirant"
        : colorsNewReverse[locale][
            variant.find(item => item.name === "Color").value
          ]) +
      " - " +
      variant.find(item => item.name === "Size").value
  } else if (title === "T-SHIRT DISCRET - FEMME") {
    newTitle =
      newTitle +
      " " +
      colorsNewReverse[locale][
        variant.find(item => item.name === "Color").value
      ] +
      " - " +
      "Col " +
      variant.find(item => item.name === "Collar").value +
      " - " +
      variant.find(item => item.name === "Size").value
  } else if (
    title.includes("E-carte cadeau") ||
    title.includes("E-carte cadeau offerte")
  ) {
    newTitle =
      newTitle + " " + variant.find(item => item.name === "Montant").value
  }
  return newTitle
}

export const linkCartProduct = (title, variant, locale, offerExclusif) => {
  let link = ""
  variant = variant.selectedOptions
  if (title === "LyneUp") {
    link =
      "/" +
      `shop/${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr" &&
        !offerExclusif
          ? "solutions-textiles-derniere-chance/?Type=Sous-vetement"
          : offerExclusif === "exclusif"
          ? "solutions-textiles-exclusif/?Type=Sous-vetement"
          : offerExclusif === "exclu"
          ? "lyneup-enplus"
          : locale === "fr"
          ? "lyneup-test"
          : locale === "en"
          ? "lyneup-test"
          : "lyneup-2b"
      }` +
      `${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr" &&
        !offerExclusif
          ? "&"
          : offerExclusif === "exclusif"
          ? "&"
          : "?"
      }` +
      "Gender=" +
      variant.find(item => item.name === "Gender").value +
      "&" +
      "Color=" +
      variant.find(item => item.name === "Color").value +
      "&" +
      "Size=" +
      variant.find(item => item.name === "Size").value
  } else if (title === "LyneFit") {
    link =
      "/" +
      `shop/${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr" &&
        !offerExclusif
          ? "solutions-textiles-derniere-chance/?Type=T-shirt-sport"
          : offerExclusif === "exclusif"
          ? "solutions-textiles-exclusif/?Type=T-shirt-sport"
          : "lynefit"
      }` +
      `${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr" &&
        !offerExclusif
          ? "&"
          : offerExclusif === "exclusif"
          ? "&"
          : "?"
      }` +
      "Gender=" +
      variant.find(item => item.name === "Gender").value +
      "&" +
      "Color=" +
      variant.find(item => item.name === "Color").value +
      "&" +
      "Size=" +
      variant.find(item => item.name === "Size").value
  } else if (title === "LyneHome") {
    link =
      "/" +
      `shop/${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr" &&
        !offerExclusif
          ? "solutions-textiles-derniere-chance/?Type=Gilet"
          : offerExclusif === "exclusif"
          ? "solutions-textiles-exclusif/?Type=Gilet"
          : "lynehome"
      }` +
      `${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr" &&
        !offerExclusif
          ? "&"
          : offerExclusif === "exclusif"
          ? "&"
          : "?"
      }` +
      "Gender=" +
      variant.find(item => item.name === "Gender").value +
      "&" +
      "Size=" +
      variant.find(item => item.name === "Size").value
  } else if (title === "Loop") {
    link = "/" + "shop/loop"
  } else if (title.includes("gift-card")) {
    link = "/" + `${locale === "fr" ? "idee-cadeau" : "gifts"}`
  } else if (title === "Matelas" || title === "Matelas Mousse") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "matelas-exclusif"
          : offerExclusif === "exclu"
          ? "matelas-enplus"
          : "matelas"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value +
      `&${
        title === "Matelas Mousse" ? "Matelas_mousse=1" : "Matelas_hybride=1"
      }`
  } else if (
    (title === "Matelas" || title === "Matelas Mousse") &&
    (offerExclusif === "exclusif" || offerExclusif === "exclu")
  ) {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "matelas-exclusif"
          : offerExclusif === "exclu"
          ? "matelas-enplus"
          : "matelas"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value +
      `&Type=${title === "Matelas Mousse" ? "mousse" : "hybride"}` +
      "&Offer=Seul"
  } else if (
    title === "1 Matelas + Oreiller" ||
    title === "1 Matelas Mousse + Oreiller"
  ) {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "matelas-exclusif"
          : offerExclusif === "exclu"
          ? "matelas-enplus"
          : "matelas"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value +
      `&Type=${
        title === "1 Matelas Mousse + Oreiller" ? "mousse" : "hybride"
      }` +
      "&Offer=Pack"
  } else if (title === "Oreiller") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "oreillers-ergonomiques-exclusif?Oreiller_universel=1"
          : "oreillers-ergonomiques?Oreiller_universel=1"
      }`
  } else if (title === "Oreiller dorsal+") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "oreillers-ergonomiques-exclusif?Oreiller_dorsalplus=1"
          : "oreillers-ergonomiques?Oreiller_dorsalplus=1"
      }`
  } else if (title === "Oreiller plat") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "oreillers-ergonomiques-exclusif?Oreiller_plat=1"
          : "oreillers-ergonomiques?Oreiller_plat=1"
      }`
  } else if (title === "Sommier Original") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "sommier-zone-original-exclusif"
          : offerExclusif === "exclu"
          ? "sommier-zone-original-enplus"
          : "sommier-zone-original"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value +
      "&" +
      "Offer=Seul"
  } else if (title === "Sommier Coffre") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "sommier-zone-coffre-exclusif"
          : offerExclusif === "exclu"
          ? "sommier-zone-coffre-enplus"
          : "sommier-zone-coffre"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value +
      "&" +
      "Offer=Seul"
  } else if (title === "Sommier Bois Massif") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "sommier-zone-bois-massif-exclusif"
          : offerExclusif === "exclu"
          ? "sommier-zone-bois-massif-enplus"
          : "sommier-zone-bois-massif"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value +
      "&" +
      "Offer=Seul"
  } else if (title === "Sommier zoné - électrique") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "lit-electrique-exclusif"
          : "lit-electrique"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value
  } else if (title === "Chaise ergonomique") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif"
          ? "chaise-bureau-ergonomique-exclusif"
          : "chaise-bureau-ergonomique"
      }`
  } else if (title === "Surmatelas") {
    link =
      "/" +
      `shop/${
        offerExclusif === "exclusif" ? "surmatelas-exclusif" : "surmatelas"
      }` +
      "?" +
      "Size=" +
      variant.find(item => item.name === "Size").value
  } else if (
    title === "T-SHIRT DISCRET - HOMME" ||
    title === "T-SHIRT DISCRET - FEMME" ||
    title === "T-SHIRT DE SPORT - HOMME" ||
    title === "T-SHIRT DE SPORT - FEMME" ||
    title === "GILET PRATIQUE - HOMME" ||
    title === "GILET PRATIQUE - FEMME"
  ) {
    link =
      "/" +
      "shop/solutions-textiles/?Type=" +
      `${
        title.includes("GILET")
          ? "Gilet"
          : title.includes("SPORT")
          ? "T-shirt-sport"
          : "Sous-vetement"
      }` +
      "&Gender=" +
      `${title.includes("HOMME") ? "Male" : "Female"}` +
      "&Color=" +
      variant.find(item => item.name === "Color").value +
      "&Col=" +
      variant.find(item => item.name === "Collar").value +
      "&" +
      "Size=" +
      variant.find(item => item.name === "Size").value
  } else if (title.includes("E-carte cadeau")) {
    link =
      "/" +
      `${
        process &&
        process.env &&
        process.env.GATSBY_ACTIVE_ENV === "eur" &&
        locale === "fr"
          ? "e-carte-cadeau"
          : locale === "fr"
          ? "idee-cadeau"
          : "gifts"
      }`
  }

  return link
}

export const returnCartImage = lang => {
  let images = {
    eur: {
      en:
        "https://static.percko.com/uploads/2b57d27f-0b45-4b09-a178-6a0856fddb65.png",
      fr:
        "https://static.percko.com/uploads/2b57d27f-0b45-4b09-a178-6a0856fddb65.png",
    },
    gbp: {
      en:
        "https://static.percko.com/uploads/2b57d27f-0b45-4b09-a178-6a0856fddb65.png",
    },
    cad: {
      en:
        "https://static.percko.com/uploads/2b57d27f-0b45-4b09-a178-6a0856fddb65.png",
      fr:
        "https://static.percko.com/uploads/2b57d27f-0b45-4b09-a178-6a0856fddb65.png",
    },
    usd: {
      en:
        "https://static.percko.com/uploads/2b57d27f-0b45-4b09-a178-6a0856fddb65.png",
    },
  }

  return images[process.env.GATSBY_CURRENCY_TYPE][lang]
}

export const getStoreEnv = () => {
  return process && process.env ? process.env.GATSBY_ACTIVE_ENV : "general"
}

export const cartEstimateDeliveryTime = (productname, monthText, locale) => {
  let expectedDelivery1 = ""
  let expectedDelivery2 = ""
  let holidayMatelasPillowArr = [
    "25-12-2024",
    "01-01-2025",
    "21-04-2025",
    "01-05-2025",
    "08-05-2025",
    "29-05-2025",
    "09-06-2025",
    "14-07-2025",
    "15-08-2025",
    "01-11-2025",
    "11-11-2025",
    "25-12-2025",
    "01-01-2026",
    "06-04-2026",
    "01-05-2026",
    "08-05-2026",
    "14-05-2026",
    "25-05-2026",
    "14-07-2026",
    "15-08-2026",
    "01-11-2026",
    "11-11-2026",
    "25-12-2026",
  ]
  let holidayEurArr = [
    "25-12-2024",
    "01-01-2025",
    "21-04-2025",
    "01-05-2025",
    "08-05-2025",
    "29-05-2025",
    "09-06-2025",
    "14-07-2025",
    "15-08-2025",
    "01-11-2025",
    "11-11-2025",
    "25-12-2025",
    "01-01-2026",
    "06-04-2026",
    "01-05-2026",
    "08-05-2026",
    "14-05-2026",
    "25-05-2026",
    "14-07-2026",
    "15-08-2026",
    "01-11-2026",
    "11-11-2026",
    "25-12-2026",
  ]
  let holidayGbpArr = [
    "25-12-2024",
    "26-12-2024",
    "01-01-2025",
    "18-04-2025",
    "21-04-2025",
    "05-05-2025",
    "26-05-2025",
    "25-08-2025",
    "25-12-2025",
    "26-12-2025",
    "01-01-2026",
    "03-04-2026",
    "06-04-2026",
    "04-05-2026",
    "25-05-2026",
    "31-08-2026",
    "25-12-2026",
    "26-12-2026",
    "28-12-2026",
  ]

  let storeUrl = getStoreEnv()

  moment.updateLocale("us", {
    holidays:
      productname === "1 Matelas + Oreiller" ||
      productname === "1 Matelas Mousse + Oreiller" ||
      productname === "Matelas Mousse" ||
      productname === "matelas mousse" ||
      productname === "Matelas" ||
      productname === "matelas" ||
      productname === "matelasFirstDeliveryDate" ||
      productname === "matelasSecondDeliveryDate" ||
      productname === "SommierElectric" ||
      productname === "SommierElectricMatelas" ||
      productname === "SommierElectricOreiller" ||
      productname === "Oreiller" ||
      productname === "oreiller" ||
      productname === "Oreiller plat" ||
      productname === "Oreiller dorsal+" ||
      productname === "Surmatelas" ||
      productname === "surmatelas" ||
      productname === "Sommier Coffre" ||
      productname === "Sommier Original" ||
      productname === "Sommier Bois Massif" ||
      productname === "Sommier zoné - électrique" ||
      productname === "sommier" ||
      productname === "Chaise ergonomique" ||
      productname === "chaise"
        ? holidayMatelasPillowArr
        : storeUrl === "gbp"
        ? holidayGbpArr
        : holidayEurArr,
    holidayFormat: "DD-MM-YYYY",
    workingWeekdays:
      storeUrl === "eur" &&
      locale === "fr" &&
      productname != "1 Matelas + Oreiller" &&
      productname != "1 Matelas Mousse + Oreiller" &&
      productname != "Matelas Mousse" &&
      productname != "matelas mousse" &&
      productname != "Matelas" &&
      productname != "matelas" &&
      productname != "matelasFirstDeliveryDate" &&
      productname != "matelasSecondDeliveryDate" &&
      productname != "SommierElectric" &&
      productname != "SommierElectricMatelas" &&
      productname != "SommierElectricOreiller" &&
      productname != "Oreiller" &&
      productname != "oreiller" &&
      productname != "Oreiller plat" &&
      productname != "Oreiller dorsal+" &&
      productname != "Surmatelas" &&
      productname != "surmatelas" &&
      productname != "Sommier Coffre" &&
      productname != "Sommier Original" &&
      productname != "Sommier Bois Massif" &&
      productname != "Sommier zoné - électrique" &&
      productname != "sommier" &&
      productname != "Chaise ergonomique" &&
      productname != "chaise"
        ? [1, 2, 3, 4, 5, 6]
        : [1, 2, 3, 4, 5],
  })

  if (storeUrl === "gbp") {
    expectedDelivery1 = moment().businessAdd(4)
    expectedDelivery2 = moment().businessAdd(5)
  } else if (storeUrl === "cad" || storeUrl === "usd") {
    expectedDelivery1 = moment().businessAdd(15)
    expectedDelivery2 = moment().businessAdd(20)
  } else {
    if (locale != "fr") {
      expectedDelivery1 = moment().businessAdd(4)
      expectedDelivery2 = moment().businessAdd(9)
    } else {
      expectedDelivery1 = moment().businessAdd(2)
      expectedDelivery2 = moment().businessAdd(4)
    }
  }

  let estimateDeliveryArr = {
    FirstDeliveryDate:
      expectedDelivery1.format("D") +
      `${
        monthText.month[Number(expectedDelivery1.format("MM")) - 1] !=
        monthText.month[Number(expectedDelivery2.format("MM")) - 1]
          ? " " + monthText.month[Number(expectedDelivery1.format("MM")) - 1]
          : ""
      }`,
    SecondDeliveryDate: expectedDelivery2.format("D"),
    MonthDeliveryDate:
      monthText.month[Number(expectedDelivery2.format("MM")) - 1],
  }

  if (
    productname === "Oreiller" ||
    productname === "oreiller" ||
    productname === "Oreiller dorsal+" ||
    productname === "Oreiller plat" ||
    productname === "SommierElectricOreiller" ||
    productname === "Surmatelas" ||
    productname === "surmatelas" ||
    productname === "chaise" ||
    productname === "Chaise ergonomique"
  ) {
    expectedDelivery1 = moment().businessAdd(3)
    expectedDelivery2 = moment().businessAdd(5)
    estimateDeliveryArr = {
      FirstDeliveryDate: expectedDelivery1.format("D"),
      SecondDeliveryDate: expectedDelivery2.format("D"),
      MonthDeliveryDate:
        monthText.month[Number(expectedDelivery2.format("MM")) - 1],
    }
  }

  if (
    productname === "1 Matelas + Oreiller" ||
    productname === "Matelas" ||
    productname === "matelas" ||
    productname === "1 Matelas Mousse + Oreiller" ||
    productname === "Matelas Mousse" ||
    productname === "matelas mousse" ||
    productname === "SommierElectricMatelas"
  ) {
    expectedDelivery1 = moment().businessAdd(3)
    expectedDelivery2 = moment().businessAdd(10)
    estimateDeliveryArr = {
      FirstDeliveryDate: expectedDelivery1.format("D"),
      SecondDeliveryDate: expectedDelivery2.format("D"),
      MonthDeliveryDate:
        monthText.month[Number(expectedDelivery2.format("MM")) - 1],
    }
  }

  if (productname === "matelasFirstDeliveryDate") {
    expectedDelivery1 = moment().businessAdd(3)
    expectedDelivery2 = moment().businessAdd(6)
    estimateDeliveryArr = {
      FirstDeliveryDate: expectedDelivery1.format("D"),
      SecondDeliveryDate: expectedDelivery2.format("D"),
      MonthDeliveryDate:
        monthText.month[Number(expectedDelivery2.format("MM")) - 1],
    }
  }

  if (productname === "matelasSecondDeliveryDate") {
    expectedDelivery1 = moment().businessAdd(4)
    expectedDelivery2 = moment().businessAdd(10)
    estimateDeliveryArr = {
      FirstDeliveryDate: expectedDelivery1.format("D"),
      SecondDeliveryDate: expectedDelivery2.format("D"),
      MonthDeliveryDate:
        monthText.month[Number(expectedDelivery2.format("MM")) - 1],
    }
  }

  if (
    productname === "Sommier Coffre" ||
    productname === "Sommier Original" ||
    productname === "Sommier Bois Massif" ||
    productname === "Sommier zoné - électrique" ||
    productname === "sommier" ||
    productname === "SommierElectric"
  ) {
    expectedDelivery1 = moment().businessAdd(6)
    expectedDelivery2 = moment().businessAdd(10)
    estimateDeliveryArr = {
      FirstDeliveryDate: expectedDelivery1.format("D"),
      SecondDeliveryDate: expectedDelivery2.format("D"),
      MonthDeliveryDate:
        monthText.month[Number(expectedDelivery2.format("MM")) - 1],
    }
  }

  if (estimateDeliveryArr.FirstDeliveryDate == 1) {
    estimateDeliveryArr.FirstDeliveryDate = "1er"
  }

  if (estimateDeliveryArr.SecondDeliveryDate == 1) {
    estimateDeliveryArr.SecondDeliveryDate = "1er"
  }

  return estimateDeliveryArr
}

export const cartEstimateDeliveryText = (
  productname,
  monthText,
  locale,
  size,
  gender,
  color,
  collar
) => {
  let deliveryDate = cartEstimateDeliveryTime(productname, monthText, locale)
  let deliveryDateString = ""

  let storeUrl = getStoreEnv()

  if (storeUrl === "gbp") {
    deliveryDateString =
      "Estimated delivery between FirstDeliveryDate and SecondDeliveryDate MonthDeliveryDate for the United Kingdom"
  } else if (storeUrl === "usd") {
    deliveryDateString =
      "Estimated delivery between FirstDeliveryDate and SecondDeliveryDate MonthDeliveryDate for the USA"
  } else if (storeUrl === "cad") {
    if (locale === "en") {
      deliveryDateString =
        "Estimated delivery between FirstDeliveryDate and SecondDeliveryDate MonthDeliveryDate for Canada"
    } else {
      deliveryDateString =
        "Livraison estimée entre le FirstDeliveryDate et SecondDeliveryDate MonthDeliveryDate au Canada"
    }
  } else {
    if (locale === "es") {
      deliveryDateString =
        "Entrega estimada entre FirstDeliveryDate y SecondDeliveryDate MonthDeliveryDate en Europa"
    } else if (locale === "de") {
      deliveryDateString =
        "Geschätzte Lieferung zwischen FirstDeliveryDate und SecondDeliveryDate MonthDeliveryDate in Europa"
    } else if (locale === "en") {
      deliveryDateString =
        "Estimated delivery between FirstDeliveryDate and SecondDeliveryDate MonthDeliveryDate for Europe"
    } else {
      deliveryDateString =
        "Livraison estimée entre le FirstDeliveryDate et SecondDeliveryDate MonthDeliveryDate en France"
    }
  }

  // if (
  //   productname === "T-SHIRT DISCRET - HOMME" &&
  //   color === "Sable" &&
  //   size === "H2.P3" &&
  //   collar === "V"
  // ) {
  //   deliveryDateString =
  //     "Livraison estimée entre le 8 et le 10 octobre en France"
  // }

  // if (
  //   productname === "GILET PRATIQUE - FEMME" &&
  //   color === "Noir" &&
  //   size === "S"
  // ) {
  //   deliveryDateString = "Livraison estimée pour le 13 novembre en France"
  // }

  if (
    productname === "1 Matelas + Oreiller" ||
    productname === "Matelas" ||
    productname === "matelas" ||
    productname === "1 Matelas Mousse + Oreiller" ||
    productname === "Matelas Mousse" ||
    productname === "matelas mousse"
  ) {
    deliveryDateString =
      "Livraison estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "matelas") {
    deliveryDateString =
      "Livraison gratuite (standard) ou livraison à l’étage. Estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "matelasFirstDeliveryDate") {
    deliveryDateString =
      "Livraison gratuite entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "matelasSecondDeliveryDate") {
    deliveryDateString =
      "Livraison à l’étage entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "SommierElectric") {
    deliveryDateString =
      "Livraison du sommier électrique estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (
    productname === "SommierElectric" &&
    (size === "80x200cm" || size === "160x200cm")
  ) {
    deliveryDateString =
      "Livraison du sommier électrique estimée entre le 10 et le 16 janvier en France"
  }

  if (productname === "SommierElectricMatelas") {
    deliveryDateString =
      "Livraison du matelas estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "SommierElectricOreiller") {
    deliveryDateString =
      "Livraison de l'oreiller estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "Surmatelas" || productname === "surmatelas") {
    deliveryDateString =
      "Livraison estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (productname === "chaise" || productname === "Chaise ergonomique") {
    deliveryDateString =
      "Livraison estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (
    productname === "Oreiller" ||
    productname === "oreiller" ||
    productname === "Oreiller dorsal+" ||
    productname === "Oreiller plat"
  ) {
    deliveryDateString =
      "Livraison estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
  }

  if (
    productname === "Sommier Coffre" ||
    productname === "Sommier Original" ||
    productname === "Sommier Bois Massif" ||
    productname === "Sommier zoné - électrique" ||
    productname === "sommier"
  ) {
    deliveryDateString =
      "Livraison estimée entre le FirstDeliveryDate et le SecondDeliveryDate MonthDeliveryDate en France"
    // deliveryDateString =
    //   "Livraison estimée entre le 10 et le 16 janvier en France"
  }

  if (productname === "Sommier Original" && size === "160x200cm") {
    deliveryDateString =
      "Livraison gratuite entre le 2 et le 10 janvier en France"
  }

  if (
    productname === "Sommier zoné - électrique" &&
    (size === "80x200cm" || size === "160x200cm")
  ) {
    deliveryDateString =
      "Livraison estimée entre le 10 et le 16 janvier en France"
  }

  if (
    productname === "E-carte cadeau" ||
    productname === "E-carte cadeau offerte"
  ) {
    deliveryDateString = "Envoyée par mail"
  }

  deliveryDateString = deliveryDateString
    .replace("FirstDeliveryDate", deliveryDate.FirstDeliveryDate)
    .replace("SecondDeliveryDate", deliveryDate.SecondDeliveryDate)
    .replace("MonthDeliveryDate", deliveryDate.MonthDeliveryDate)

  return deliveryDateString
}
