import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

const ChristmasGbpStickyBannerWrapper = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: relative;
  background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  overflow: hidden;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Container = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 20px;

  @media (max-width: 480px) {
    padding: 8px 15px;
  }
`

const MobileButton = styled(Link)`
  display: none;
  color: ${props => props.theme.saleColor.textColor};
  margin-left: 10px;

  @media (max-width: 600px) {
    display: inline-block;
  }
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  text-align: left;

  @media (max-width: 600px) {
    // width: 100%;
  }
`

const LeftText = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 21px;
  line-height: 1;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileLeftText = styled.div`
  display: none;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 18px;
  line-height: 1;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    display: block;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }

  @media (max-width: 340px) {
    font-size: 14px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 16px;
  line-height: 1;

  > span {
    font-size: 21px;
    font-family: "Museo";
    font-weight: 900;
    // background: ${props => props.theme.colors.white.base};
    // color: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};
    background: ${props => props.theme.saleColor.textColor};
    color: ${props => props.theme.saleColor.bgColor};
    padding: 4px 8px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0px 7px;

    @media (max-width: 600px) {
      margin: 0px 5px;
    }
  }

  @media (max-width: 600px) {
    // font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const Button = styled(Link)`
  color: ${props => props.theme.saleColor.textColor};
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 25px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 600px) {
    display: none;
  }

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }
`

const ChristmasGbpStickyBanner = ({ intl }) => {
  const [activeFlag, setactiveFlag] = useState(true)

  let ButtonHide = false

  let regex = /(lyneup|lynefit|lynehome|loop)/
  if (isBrowser) {
    ButtonHide = regex.test(window.location.pathname)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })

    $(document).ready(function() {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })
  }

  useEffect(() => {
    // BottomBarActive()
  }, [intl.locale])
  return (
    activeFlag && (
      <ChristmasGbpStickyBannerWrapper className="LyneHomeStickyBanner">
        {ButtonHide ? (
          <Container>
            <TextBox>
              <LeftText>PERCKO SALE</LeftText>
              <MobileLeftText>PERCKO SALE</MobileLeftText>
              <RightText>
                <span>25%</span>
                <div>
                  OFF 2<sup>nd</sup> top
                </div>
              </RightText>
            </TextBox>
          </Container>
        ) : (
          <Container>
            <LinkBox to="/shop/lyneup-test/">
              <LinkButtonTitle>{"Let's go >"}</LinkButtonTitle>
            </LinkBox>
            <TextBox>
              <LeftText>PERCKO SALE</LeftText>
              <MobileLeftText>PERCKO SALE</MobileLeftText>
              <RightText>
                <span>25%</span>
                <div>
                  OFF 2<sup>nd</sup> top
                </div>
              </RightText>
            </TextBox>
            <Button to="/shop/lyneup-test/">{"Let's go >"}</Button>
            <MobileButton to="/shop/lyneup-test/">{">"}</MobileButton>
          </Container>
        )}
      </ChristmasGbpStickyBannerWrapper>
    )
  )
}

export default injectIntl(ChristmasGbpStickyBanner)
