import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { useLocation } from "@reach/router"
import OffresIcon from "../../../../static/OffresMedail.png"

const FlashStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  text-align: center;
  background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  position: relative;
  overflow: hidden;
  min-height: 53px;
  display: flex;

  @media (max-width: 720px) {
    min-height: 50px;
  }

  @media (max-width: 480px) {
    min-height: 44px;
  }

  @media (max-width: 450px) {
    padding: 8px 10px;
  }

  @media (max-width: 350px) {
    min-height: 40px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  text-align: left;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const LeftText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 18px;
  line-height: 1;

  strong {
    font-weight: 900;
    margin: 0px 5px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  margin-left: 5px;

  strong {
    font-weight: 900;
    margin: 0px 5px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`

const ClockImage = styled.img`
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  margin: 0px;
`

const FlashLeftText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Museo";
  font-weight: 400;
  font-size: 18px;
  line-height: 1;

  strong {
    font-weight: 900;
    margin: 0px 5px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`

const FlashRightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Museo";
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  margin-left: 5px;

  strong {
    font-weight: 900;
    margin: 0px 5px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`

const FlashStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const [shopButtonHide, setShopButtonHide] = useState(false)

  const { pathname } = useLocation()

  const checkButtonVisibility = () => {
    let currentPath = ""
    let ButtonHide = false
    let isShopPage = false

    let regex = /(lyneup|lynefit|lynehome|matelas|surmatelas|oreiller|sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique|chaise|shop|panier|cart|wearable-technology|solutions-textiles)/
    if (isBrowser) {
      currentPath = window.location.pathname

      let pathArr = currentPath.split("/")

      if (
        pathArr[pathArr.length - 1] === "shop" ||
        pathArr[pathArr.length - 2] === "shop"
      ) {
        isShopPage = true
      }
      ButtonHide = regex.test(currentPath)
      setShopButtonHide(ButtonHide)
    }
  }

  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("MaiStickyBanner") && setLyneHomeStickyHide(true)
    }
  }

  useEffect(() => {
    checkVisibilityBanner()
  }, [intl.locale])

  useEffect(() => {
    isBrowser && checkButtonVisibility()
  }, [pathname])
  return (
    !LyneHomeStickyHide && (
      <FlashStickyBannerWrapper className="LyneHomeStickyBanner">
        {shopButtonHide ? (
          <Container>
            <TextBox>
              <FlashLeftText>
                {/* <ClockImage className="lazyload" data-src={OffresIcon} /> */}
                <strong>Offres flash -10%</strong>
                supplémentaires
              </FlashLeftText>
              <FlashRightText>
                avec le code <strong>FLASH</strong> jusqu'au 04/09
              </FlashRightText>
            </TextBox>
          </Container>
        ) : (
          <Container>
            <TextBox>
              <LeftText>
                {/* <ClockImage className="lazyload" data-src={OffresIcon} /> */}
                <strong>Offres flash</strong>
                sur tout le site
              </LeftText>
              <RightText>
                et <strong>-10%</strong> supplémentaires au panier jusqu'au
                04/09
              </RightText>
            </TextBox>
          </Container>
        )}
      </FlashStickyBannerWrapper>
    )
  )
}

export default injectIntl(FlashStickyBanner)
